export default {
  expectedDuration: {
    id: 'resolution_flow_return_confirmation_page_description_expected_durations',
    defaultMessage: `{duration, number} {duration, plural,
              one {business day}
              other {business days}
            }`,
  },
  heading: {
    id: 'resolution_flow_return_confirmation_page_title_return',
    defaultMessage: 'All set for your return!',
  },
  pageTitle: {
    id: 'resolution_flow_return_confirmation_page_breadcrumb_return_request',
    defaultMessage: 'Return request',
  },
  shippedHeading: {
    id: 'resolution_flow_return_confirmation_page_shipped_variant_title_return',
    defaultMessage: 'First, return the item.',
  },

  subHeading: {
    id: 'resolution_flow_return_confirmation_page_info_title_repair_replace_requested',
    defaultMessage: 'Repair or replace requested',
  },

  description: {
    id: 'resolution_flow_return_confirmation_page_description_repair_or_replace_option',
    defaultMessage:
      'Once the seller receives your item, they’ll repair or replace it within {duration, html} and then send it back to you. It may take a few more days to arrive.',
  },
  shippedSubDescription: {
    id: 'resolution_flow_return_confirmation_page_shipped_variant_description',
    defaultMessage:
      'The package may already be on its way to you. If that’s the case, try refusing delivery for the fastest return. If refusal isn’t an option, follow our simple return instructions.',
  },
}
