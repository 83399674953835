<template>
  <component
    :is="returnConfirmationVariant.variant"
    v-if="
      returnConfirmationVariant.activeStep &&
      data?.resolution &&
      returnConfirmationVariant.variant
    "
    :active-timeline-step="returnConfirmationVariant.activeStep"
    :resolution="data.resolution"
  />
</template>

<script lang="ts" setup>
import { navigateTo, useRoute } from '#imports'
import { computed } from 'vue'

import { useLogger } from '@backmarket/nuxt-module-logger/useLogger'

import { DASHBOARD as DASHBOARD_ROUTE } from '~/scopes/dashboard/routes'

import { CUSTOMER_CARE as CUSTOMER_CARE_ROUTES } from '../../../route-names'

import { useReturnConfirmationData } from './composables/useReturnConfirmationData'
import { useReturnConfirmationVariant } from './composables/useReturnConfirmationVariant'

const logger = useLogger()
const { params } = useRoute()

const { data, error } = await useReturnConfirmationData({
  resolutionId: params.resolutionId as string,
})

if (error.value) {
  logger.error(
    '[SALES_CUSTOMER_CARE] Unexpected Error creating return confirmation page',
    {
      httpError: error,
    },
  )

  await navigateTo({
    name: DASHBOARD_ROUTE.MY_ORDERS.SELF,
  })
}

const returnConfirmationVariant = computed(() => {
  const { activeStep, variant, variantError } = useReturnConfirmationVariant({
    orderlineTimeline: data.value?.orderlineTimeline,
    resolution: data.value?.resolution,
  })

  return {
    activeStep,
    variant,
    variantError,
  }
})

if (returnConfirmationVariant.value.variantError) {
  await navigateTo({
    name: CUSTOMER_CARE_ROUTES.RESOLUTION.RESOLUTION_CONTACT_CONFIRMATION,
    params: {
      resolutionId: params.resolutionId as string,
    },
  })
}
</script>
