import type { ProblemCustomerIssue } from '../../../api-specs-help-center/diagnosis/types/problemResponse'
import type { Country } from '../../../standards/Country'
import type { DateTime } from '../../../standards/DateTime'

export const DECLARE_WARRANTY_STATE = {
  inWarranty: 'IN_WARRANTY',
  thirdPartyRepair: 'THIRD_PARTY_REPAIR',
  waterOrDropped: 'WATER_OR_DROPPED',
} as const

export const RESOLUTION_STATE = {
  closed: 'CLOSED',
  created: 'CREATED',
  failed: 'FAILED',
  initiated: 'INITIATED',
  initiationFailed: 'INITIATION_FAILED',
  ongoing: 'ONGOING',
} as const

export const ORDERLINE_STATE = {
  new: 'NEW',
  paid: 'PAID',
  validated: 'VALIDATED',
  shipped: 'SHIPPED',
  cancelled: 'CANCELLED',
  refundedPreDelivery: 'REFUNDED_PRE_DELIVERY',
  refundedPostDelivery: 'REFUNDED_POST_DELIVERY',
  notPaid: 'NOT_PAID',
  pending: 'PENDING',
  onHold: 'ON_HOLD',
  replaced: 'REPLACED',
} as const

export type OrderlineState =
  (typeof ORDERLINE_STATE)[keyof typeof ORDERLINE_STATE]

export type ProblemContext = {
  orderline: {
    publicId: string
    id: string
    orderId: string
    state: OrderlineState
    market: string
    dateDelivery: DateTime | null
    maxDeliveryDate: DateTime | null
    price: string
    isAutoRefundable: boolean
  }
  seller: {
    publicId: string
    id: string
    isExitProcess: boolean
    automaticReturnLabelEnabled: boolean
  }
  customer: {
    publicId: string
    bmUserId: string
    id: string
    email: string
    firstName: string
    lastName: string
    phoneNumber: string | null
  }
  item: {
    brand: string
    model: string
    weight: number
    category3Id: string
    marketplaceCategory3Id: string
    imei: string | null
    serialNumber: string | null
  }

  problem: {
    issues: Array<ProblemCustomerIssue>
  }
  declaredWarrantyState:
    | (typeof DECLARE_WARRANTY_STATE)[keyof typeof DECLARE_WARRANTY_STATE]
    | null
  shipmentEligibility: {
    isShipmentEligible: boolean
    isItemEligible: boolean
  }
}

export const RESOLUTION_OPTION_TYPE = {
  factoryRepair: 'FACTORY_REPAIR',
  shopRepair: ' SHOP_REPAIR',
  repairReplace: 'REPAIR_REPLACE',
  remoteAssistance: 'REMOTE_ASSISTANCE',
  refund: 'REFUND',
  sellerRepair: 'SELLER_REPAIR',
  sellerReplace: 'SELLER_REPLACE',
  replace: 'REPLACE',
  liveChat: 'LIVE_CHAT',
  straightRefund: 'STRAIGHT_REFUND',
} as const

export const RESOLUTION_OPTION_SERVICE_NAME = {
  asp: 'ASP',
  backRepair: 'BACKREPAIR',
  orderManagement: 'ORDER_MANAGEMENT',
}

export const RESOLUTION_OPTION_FALLBACK_REASON = {
  shipment: 'SHIPMENT',
  failure: 'FAILURE',
  resolutionFailed: 'RESOLUTION_FAILED',
  asyncPayment: 'ASYNC_PAYMENT',
}

export type ResolutionOptionType =
  (typeof RESOLUTION_OPTION_TYPE)[keyof typeof RESOLUTION_OPTION_TYPE]

export type ResolutionOption = {
  type: ResolutionOptionType
  serviceName: (typeof RESOLUTION_OPTION_SERVICE_NAME)[keyof typeof RESOLUTION_OPTION_SERVICE_NAME]
  fallbackReason:
    | (typeof RESOLUTION_OPTION_FALLBACK_REASON)[keyof typeof RESOLUTION_OPTION_FALLBACK_REASON]
    | null
  isDataRemovalRequired: boolean
  isShipmentRequired: boolean
  dispatchAddress: {
    id: string
    street: string
    city: string
    postalCode: string
    country: Country
    company: string | null
    street2: string | null
    stateOrProvince: string | null
  } | null
  deliveryAddressId: string | null
  createShipment: boolean
}

export type ResolutionRequest = {
  message: string | null
  attachmentHandles: Array<string> | null
  isDataRemovalConfirmed: boolean | null
  shipment: {
    id: string
    carrier: string
    trackingNumber: string
    labelUrl: string
    labelExpirationDate: DateTime
  } | null
  fromExistingClaim: boolean | null
  extraData: Record<string, string> | null
}

export type Resolution = {
  id: string
  problemContext: ProblemContext
  resolutionOption: ResolutionOption
  resolutionRequest: ResolutionRequest
  state: (typeof RESOLUTION_STATE)[keyof typeof RESOLUTION_STATE]
  externalId: string | null
}
