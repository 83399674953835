import {
  ORDERLINE_TIMELINE_STEP_TYPE,
  type StepType,
} from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'
import type { I18nToken } from '@backmarket/nuxt-module-i18n/types'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import translations from './ReplaceConfirmation.translations'

export const descriptionDuration = 3

export type ReplaceConfirmationConfig = Partial<
  Record<
    StepType,
    {
      subHeading: string
      heading: string
      descriptions: Array<string>
      actions: {
        displayReturnActions: boolean
        displayGetMoreHelpAction: boolean
        displayGoToMyOrdersAction: boolean
      }
      footerActions: {
        displayReturnActions: boolean
      } | null
    }
  >
>

export const replaceConfirmationConfig = (): ReplaceConfirmationConfig => {
  const i18n = useI18n()

  return {
    [ORDERLINE_TIMELINE_STEP_TYPE.delivery]: {
      subHeading: i18n(translations.subHeading),
      heading: i18n(translations.heading),
      descriptions: [
        (
          i18n.html(translations.description, {
            duration: i18n(translations.expectedDuration, {
              duration: descriptionDuration,
            }),
          }) as I18nToken[]
        ).join(''),
      ],
      actions: {
        displayReturnActions: true,
        displayGetMoreHelpAction: true,
        displayGoToMyOrdersAction: false,
      },
      footerActions: null,
    },

    [ORDERLINE_TIMELINE_STEP_TYPE.shipment]: {
      subHeading: i18n(translations.subHeading),
      heading: i18n(translations.shippedHeading),
      descriptions: [
        i18n(translations.shippedSubDescription),
        (
          i18n.html(translations.description, {
            duration: i18n(translations.expectedDuration, {
              duration: descriptionDuration,
            }),
          }) as I18nToken[]
        ).join(''),
      ],
      actions: {
        displayReturnActions: false,
        displayGetMoreHelpAction: true,
        displayGoToMyOrdersAction: true,
      },
      footerActions: {
        displayReturnActions: true,
      },
    },
  }
}
