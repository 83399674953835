<template>
  <PrintLabelAction
    v-if="shipmentUrl"
    :shipment-label-url="shipmentUrl"
    :tiny="tinyActions"
    :variant="variant"
  />

  <ProofOfPurchaseAction
    v-if="hasProofOfPurchaseAction"
    :order-id="orderId"
    :tiny="tinyActions"
    :variant="proofOfPurchaseActionVariant"
  />
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { RESOLUTION_OPTION_SERVICE_NAME } from '@backmarket/http-api/src/api-specs-after-sale-experience/resolution-engine/types/common'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import PrintLabelAction from '../PrintLabelAction/PrintLabelAction.vue'
import ProofOfPurchaseAction from '../ProofOfPurchaseAction/ProofOfPurchaseAction.vue'

import type { ReturnActionsProps } from './ReturnActions.types'

const { resolution, tinyActions, variant } = withDefaults(
  defineProps<ReturnActionsProps>(),
  {
    tinyActions: false,
    variant: 'primary',
  },
)

const orderId = computed(() => resolution?.problemContext?.orderline?.orderId)

const shipmentUrl = computed(
  () => resolution?.resolutionRequest?.shipment?.labelUrl,
)

const hasProofOfPurchaseAction = computed(
  () =>
    resolution?.resolutionOption?.serviceName !==
    RESOLUTION_OPTION_SERVICE_NAME.backRepair,
)

const proofOfPurchaseActionVariant = computed(() =>
  !isEmpty(shipmentUrl.value) ? 'secondary' : variant,
)
</script>
