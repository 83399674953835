import { defineAsyncComponent } from 'vue'

import type { Resolution } from '@backmarket/http-api/src/api-specs-after-sale-experience/resolution-engine/types/common'
import type {
  OrderlineTimeline,
  StepType,
} from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'
import { isEmpty } from '@backmarket/utils/object/isEmpty'

import { getActiveTimelineStep } from '../../utils/getActiveTimelineStep'
import { returnConfirmationPageConfig } from '../ReturnConfirmationPage.config'

interface useReturnConfirmationVariantReturnValue {
  activeStep?: StepType | ''
  variantError?: Error
  variant?: ReturnType<typeof defineAsyncComponent>
}

export function useReturnConfirmationVariant({
  orderlineTimeline,
  resolution,
}: {
  orderlineTimeline?: Array<OrderlineTimeline>
  resolution?: Resolution
}): useReturnConfirmationVariantReturnValue {
  if (orderlineTimeline && resolution) {
    try {
      const orderlineState = resolution.problemContext?.orderline?.state

      const activeStep = getActiveTimelineStep({
        orderlineState,
        orderlineTimeline,
      })

      if (isEmpty(activeStep)) {
        throw new Error('active orderline timeline step unknown')
      }

      const variant =
        returnConfirmationPageConfig[resolution?.resolutionOption?.type]

      if (!variant) {
        throw new Error(
          'no return confirmation variant found for this resolution option type',
        )
      }

      const { allowedOrderlineTimelineStep, component } = variant

      if (!allowedOrderlineTimelineStep.includes(activeStep)) {
        throw new Error(
          'the orderline active timeline step is not allowed for this return confirmation variant',
        )
      }

      return {
        activeStep,
        variant: component,
      }
    } catch (error) {
      return {
        variantError: error as Error,
      }
    }
  }

  return {}
}
