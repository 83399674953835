import {
  ORDERLINE_STATE,
  type OrderlineState,
} from '@backmarket/http-api/src/api-specs-after-sale-experience/resolution-engine/types/common'
import {
  ORDERLINE_TIMELINE_STEP_TYPE,
  type OrderlineTimeline,
  type StepType,
} from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'

type getActiveTimelineStepReturnValue = StepType | ''

export function getActiveTimelineStep({
  orderlineState,
  orderlineTimeline,
}: {
  orderlineState: OrderlineState
  orderlineTimeline: Array<OrderlineTimeline>
}): getActiveTimelineStepReturnValue {
  const activeStep = orderlineTimeline.find((item) => item.current)

  if (
    activeStep?.type === ORDERLINE_TIMELINE_STEP_TYPE.validation &&
    orderlineState === ORDERLINE_STATE.shipped
  ) {
    return ORDERLINE_TIMELINE_STEP_TYPE.shipment
  }

  return activeStep?.type ? activeStep.type : ''
}
