<template>
  <ReturnConfirmationShell
    :active-timeline-step="props.activeTimelineStep"
    :current-page-title="i18n(translations.pageTitle)"
    data-test="replace-confirmation-page"
    illustration-url="/img/common/notFound.svg"
    :resolution="props.resolution"
  >
    <template v-if="config?.subHeading" #subHeading>
      {{ config.subHeading }}
    </template>

    <template v-if="config?.heading" #heading>
      {{ config.heading }}
    </template>

    <template v-if="config?.descriptions" #description>
      <div class="space-y-16">
        <p
          v-for="(description, index) in config.descriptions"
          :key="index"
          class="body-1"
        >
          {{ description }}
        </p>
      </div>
    </template>

    <template v-if="config?.actions" #actions>
      <ReturnActions
        v-if="config.actions.displayReturnActions"
        :resolution="resolution"
      />

      <GoToMyOrdersAction
        v-if="config.actions.displayGoToMyOrdersAction"
        variant="primary"
      />

      <GetMoreHelpAction
        v-if="config.actions.displayGetMoreHelpAction"
        :resolution-id="resolution.id"
      />
    </template>

    <template #footerActions v-if="config?.footerActions">
      <div class="mt-16 flex flex-col space-y-12">
        <ReturnActions
          v-if="config.footerActions.displayReturnActions"
          :resolution="resolution"
          :tiny-actions="true"
          variant="secondary"
        />
      </div>
    </template>
  </ReturnConfirmationShell>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'

import GetMoreHelpAction from '../Actions/GetMoreHelpAction/GetMoreHelpAction.vue'
import GoToMyOrdersAction from '../Actions/GoToMyOrdersAction/GoToMyOrdersAction.vue'
import ReturnActions from '../Actions/ReturnActions/ReturnActions.vue'
import ReturnConfirmationShell from '../ReturnConfirmationShell/ReturnConfirmationShell.vue'

import { replaceConfirmationConfig } from './ReplaceConfirmation.config'
import translations from './ReplaceConfirmation.translations'
import type { ReplaceConfirmationProps } from './ReplaceConfirmation.types'

const props = defineProps<ReplaceConfirmationProps>()

const i18n = useI18n()

const config = computed(
  () => replaceConfirmationConfig()[props.activeTimelineStep],
)
</script>
