<template>
  <main class="contents">
    <div class="grid w-full grid-cols-1 grid-rows-[auto_1fr]">
      <div class="bg-static-info-low">
        <RevContainer>
          <div
            class="grid-standard mx-auto grid max-w-[1440px] grid-rows-[auto_1fr]"
          >
            <RevBreadcrumb
              ariaLabel="breadcrumb-return-confirmation-page"
              :breads="breads"
              class="col-span-full col-start-1 max-lg:my-8 max-lg:-ml-24"
            />

            <div
              class="col-span-full mb-24 lg:col-span-6 lg:col-start-2 lg:my-56"
            >
              <h2
                v-if="$slots.subHeading"
                class="text-static-default-low body-2 mb-4"
              >
                <slot name="subHeading" />
              </h2>

              <h1
                v-if="$slots.heading"
                class="heading-1 mb-24 lg:mb-12"
                data-qa="title-confirm"
              >
                <slot name="heading" />
              </h1>

              <div v-if="$slots.description" class="body-1 mb-24">
                <slot name="description" />
              </div>

              <div
                class="flex flex-col items-center justify-center space-y-16 text-center md:flex-row md:flex-wrap md:justify-start md:gap-16 md:space-y-0"
              >
                <slot v-if="$slots.actions" name="actions" />
              </div>
            </div>

            <div class="hidden lg:col-span-3 lg:col-start-9 lg:block">
              <div class="flex h-full w-full items-center justify-center">
                <RevIllustration
                  alt="illustration-return-confirmation-page"
                  :height="252"
                  :src="illustrationUrl"
                  :width="252"
                />
              </div>
            </div>
          </div>
        </RevContainer>
      </div>

      <RevContainer>
        <div class="grid-standard mx-auto grid max-w-[1440px] py-24 lg:py-56">
          <div class="col-span-full lg:col-span-4 lg:col-start-2">
            <h2 v-if="!isProTipInstructions" class="heading-2 mb-16 lg:hidden">
              {{ i18n(contentTitle) }}
            </h2>

            <ReturnReminder
              v-if="hasCustomerRemovedData"
              data-test="return-reminder"
            />
          </div>

          <div class="col-span-full" :class="returnStepsContainer">
            <h2 v-if="isProTipInstructions" class="heading-3 mb-16">
              {{ i18n(translations.propTipInstructions) }}
            </h2>

            <h2
              v-if="!isProTipInstructions"
              class="hidden lg:heading-2 lg:mb-16 lg:block"
            >
              {{ i18n(contentTitle) }}
            </h2>

            <ReturnSteps
              v-if="expiryDate"
              data-test="return-steps"
              :expiry-date="expiryDate"
            >
            </ReturnSteps>

            <div
              v-if="$slots.footerActions"
              class="mt-16 flex w-fit flex-col space-y-12"
            >
              <slot name="footerActions" />
            </div>
          </div>
        </div>
        <RevDivider />
      </RevContainer>
    </div>

    <RevContainer
      class="mb-72 mt-24 flex items-center justify-center text-center"
    >
      <RevLink class="body-1-link" :to="myOrderRoute">
        {{ i18n(translations.footerCta) }}
      </RevLink>
    </RevContainer>
  </main>
</template>

<script lang="ts" setup>
import { useRoute, useRouteLocationWithLocale } from '#imports'
import { computed } from 'vue'

import {
  ORDERLINE_TIMELINE_STEP_TYPE,
  type StepType,
} from '@backmarket/http-api/src/api-specs-my-orders-api/types/common'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { tw } from '@backmarket/utils/string/tw'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'
import type { Bread } from '@ds/components/Breadcrumb/Breadcrumb.vue'
import { RevContainer } from '@ds/components/Container'
import { RevDivider } from '@ds/components/Divider'
import { RevIllustration } from '@ds/components/Illustration'
import { RevLink } from '@ds/components/Link'

import { ROUTE_NAMES } from '@/scopes/dashboard/constants'

import ReturnReminder from '../ReturnReminder/ReturnReminder.vue'
import ReturnSteps from '../ReturnSteps/ReturnSteps.vue'

import translations from './ReturnConfirmationShell.translations'
import type { ReturnConfirmationShellProps } from './ReturnConfirmationShell.types'

const { activeTimelineStep, currentPageTitle, resolution } = withDefaults(
  defineProps<ReturnConfirmationShellProps>(),
  {
    activeTimelineStep: '',
  },
)

const i18n = useI18n()
const route = useRoute()
const resolveLocalizedRoute = useRouteLocationWithLocale()

const myOrderRoute = resolveLocalizedRoute({
  name: ROUTE_NAMES.DASHBOARD.MY_ORDERS.SELF,
})

const breads: Array<Bread> = [
  {
    link: myOrderRoute,

    title: i18n(translations.myOrdersCta),
  },
  {
    title: currentPageTitle,
    link: route.path,
  },
]

const contentTitle = computed(() =>
  resolution?.resolutionRequest?.isDataRemovalConfirmed
    ? translations.shipDeviceInstructions
    : translations.returnInstructions,
)

const expiryDate = computed(() =>
  resolution?.resolutionRequest?.shipment?.labelExpirationDate
    ? new Date(resolution?.resolutionRequest?.shipment?.labelExpirationDate)
    : null,
)

const hasCustomerRemovedData = computed(() =>
  Boolean(resolution?.resolutionRequest?.isDataRemovalConfirmed),
)

const isProTipInstructions = computed(() => {
  if (isEmpty(activeTimelineStep)) {
    return false
  }

  const validSteps: Array<StepType> = [
    ORDERLINE_TIMELINE_STEP_TYPE.validation,
    ORDERLINE_TIMELINE_STEP_TYPE.shipment,
  ]

  return validSteps.includes(activeTimelineStep)
})

const returnStepsContainer = computed(() =>
  hasCustomerRemovedData.value
    ? tw`lg:col-span-6 lg:col-start-6`
    : tw`lg:col-span-10 lg:col-start-2`,
)
</script>
